<template>
  <v-container v-if="has_view_permission">
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <add_edit_form v-if="add_button" activator_name="Add New"
                       form_title="Add New" :url="add_url"
                       activator_icon="mdi-plus-thick" action="add" @form_status="get_lists"
                       big_btn="true"
                       snackbar_text="{value} has been added successfully"
                       :loading="reload_btn"
        ></add_edit_form>
        <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="id"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
          item-key="id"
          name="cause_table"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            Case Filed BY
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item.cause="{item}">

          <span style="overflow-wrap: anywhere;">{{ item.cause }}</span>

        </template>
        <template v-slot:item.actions="{ item }">
          <add_edit_form v-if="edit_button" activator_name="Edit"
                         :form_title="`Edit#${item.name}`" :passed_item="item" :url="edit_url"
                         activator_icon="mdi-pencil" action="edit" @form_status="get_lists"
                         tooltip_text="Edit"
                         :snackbar_text="`${item.name} has been updated successfully`">

          </add_edit_form>
          <delete_dialog v-if="delete_button" @form_status="get_lists" :passed_item="item"
                         :dialogue_text="`Are you sure you want to Delete # ${item.name}?`"
                         :url="`${delete_url.replace('{id}', item.id)}`"
                         activator_name="Delete" activator_icon="mdi-delete"
                         tooltip_text="Delete"
                         :snackbar_text="`${item.name} deleted`"
                         action="delete"
          ></delete_dialog>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import add_edit_form from "@/components/app_setting/case_filed_by/add_edit_form";
import delete_dialog from "@/components/app_setting/case_filed_by/delete_dialog";
import app_setting_api from "@/constants/app_setting/app_setting_api"


export default {
  name: "CaseFiledByList",
  components: {add_edit_form, delete_dialog},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('app_settings.view_casefiledby')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('app_settings.add_casefiledby')
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('app_settings.change_casefiledby')
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('app_settings.delete_casefiledby')
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'Code',
        align: 'center',
        filterable: true,
        value: 'code',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
    ],
    table_items: [],
    search: '',
    show_snackbar: false,
    snackbar_text: null,
    add_url: app_setting_api.case_filed_by.list,
    edit_url: app_setting_api.case_filed_by.single,
    delete_url: app_setting_api.case_filed_by.single,
  }),
  methods: {
    async get_lists() {
      this.axios_conf
      this.reload_btn = true
      this.table_loading = true
      let self = this
      this.table_items = []
      let config = {
        headers: {
          multi: true
        }
      }
      await axios.get(app_setting_api.case_filed_by.list, config).then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.reload_btn = false
        self.table_loading = false
      }).catch((err) => {
        // console.log(err)
        this.$store.commit("update_snackbar", {text: `Error Occurred #${err}`, status: true})
      })
    },
  },
  mounted() {
    this.get_lists();
  }
}
</script>

<style>
div[name="cause_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>