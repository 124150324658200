const app_setting_api = {
    cause: {
        list: "app_setting/causes/",
        single: "app_setting/causes/{id}/",
    },
    district: {
        list: "app_setting/district/",
        single: "app_setting/district/{id}/",
    },
    loan_type: {
        list: "app_setting/loan_type/",
        single: "app_setting/loan_type/{id}/",
    },
    court_type: {
        list: "app_setting/court_type/",
        single: "app_setting/court_type/{id}/",
    },
    court: {
        list: "app_setting/court/",
        single: "app_setting/court/{id}/",
    },
    case_type: {
        list: "app_setting/case_type/",
        single: "app_setting/case_type/{id}/",
    },
    ex_type: {
        list: "app_setting/ex_type/",
        single: "app_setting/ex_type/{id}/",
    },
    case_filed_by: {
        list: "app_setting/case_filed_by/",
        single: "app_setting/case_filed_by/{id}/",
    },
    purpose: {
        list: "app_setting/purpose/",
        single: "app_setting/purpose/{id}/",
    },
    mode_of_recovery: {
        list: "app_setting/mode_of_recovery/",
        single: "app_setting/mode_of_recovery/{id}/",
    },
    expenses: {
        list: "app_setting/expenses/",
        single: "app_setting/expenses/{id}/",
    },
    disposal_through: {
        list: "app_setting/disposal_through/",
        single: "app_setting/disposal_through/{id}/",
    },
    disposal_nature: {
        list: "app_setting/disposal_nature/",
        single: "app_setting/disposal_nature/{id}/",
    },
    disposal_result: {
        list: "app_setting/disposal_result/",
        single: "app_setting/disposal_result/{id}/",
    },
    direction_binding: {
        list: "app_setting/direction_binding/",
        single: "app_setting/direction_binding/{id}/",
    },
};

export default app_setting_api;
