<template>
  <v-container>
    <case-filed-by-list/>
  </v-container>
</template>

<script>
import CaseFiledByList from "@/components/app_setting/case_filed_by/CaseFiledByList";

export default {
  name: "CaseFiledByPage",
  components: {
    CaseFiledByList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Case Filed By'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>